<template>
  <div>
    <!-- Título principal -->
    <div class="d-flex mb-10 my-8 flex-wrap sticky-card py-8 titleContainer" width="100%">
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <div class="d-flex">
        <h3 class="mt-2 primary--text mr-3" v-if="cliente.Nombre">
          {{ cliente.Fichan }} | {{ cliente.Nombre }}
        </h3>
      </div>
      <v-spacer></v-spacer>
      <h1 class="justify-center ml-3">Cargar insumos a cuentas</h1>
      <v-spacer></v-spacer>
    </div>
    <!-- Información paciente + detalle cuenta -->
    <v-row v-if="activeDev" class="pa-0 ma-0 d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
      <v-col class="pa-0 ma-0" cols="6">
        <!-- Datos paciente + dr. -->
        <v-row class="mb-8 d-flex flex-column">
          <!-- Datos paciente -->
          <v-col>
            <h2 class="ml-2 ma-2">Paciente</h2>
            <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
              <v-text-field v-model="cliente.Nombre" outlined readonly label="Nombre" dense class="ma-2"></v-text-field>
              <v-text-field outlined label="N° de ficha" v-model="cliente.Fichan" readonly dense class="ma-2">
              </v-text-field>
            </div>
            <div>
              <v-text-field outlined label="Propietario" v-model="cliente.Amo" readonly dense class="ma-2">
              </v-text-field>
            </div>
          </v-col>
          <!-- Profesional -->
          <v-col>
            <div class="ma-2">
              <div v-if="licen.Var37 == 1">
                <v-autocomplete v-model="selectDoctorSupervisor" :items="doctors" item-text="Nombre" return-object
                  outlined dense label="Profesional" class="ma-2"></v-autocomplete>
              </div>
              <div v-else>
                <v-text-field class="ma-2" v-model="doctor.Nombre" outlined label="Profesional" dense
                  readonly></v-text-field>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- Resumen de venta -->
        <div>
          <v-card class="pa-3 mb-5 rounded-xl ma-2">
            <div class="d-flex justify-space-between">
              <h2 v-if="selectTipoCuenta == 'hospital'" class="ml-2 ma-2">Cargar insumos de hospital</h2>
              <h2 v-if="selectTipoCuenta == 'cliente'" class="ml-2 ma-2">Cargar insumos a la cta del cliente</h2>
              <v-chip color="primary" tile v-if="!cliente.Nombre">Cliente no seleccionado</v-chip>
            </div>

            <v-tabs color="primary" class="mb-5" v-model="tab" grow>
              <v-tab key="individual"> Individual </v-tab>
              <v-tab key="pack"> Pack </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item key="individual">
                <v-card elevation="0">
                  <h2 ref="titleprodurefind" class="ml-2 ma-2">
                    Venta por unidades
                  </h2>

                  <v-card v-if="selectProduCodigoSale" color="primary" class="pa-3 ma-2 white--text d-flex align-center">
                    <h3 class="white--text">{{ selectProduNameSale }}</h3>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" @click="btnCleanSelectSalePro" dark icon v-bind="attrs" v-on="on">
                          <v-icon color="white"> mdi-close </v-icon>
                        </v-btn>
                      </template>
                      <span>Limpiar producto</span>
                    </v-tooltip>
                  </v-card>
                  <!-- resumen -->
                  <div>
                    <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                      <v-text-field outlined label="Cantidad" v-model="quantitySale" min="1" type="number" dense
                        class="ma-2">
                      </v-text-field>
                      <v-text-field outlined label="Precio" v-model="selectProduPriceSale" readonly dense class="ma-2">
                      </v-text-field>
                      <v-text-field outlined label="Descuento %" v-model="percentDiscountSale" min="0" max="100"
                        type="number" dense class="ma-2">
                      </v-text-field>
                    </div>
                    <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                      <v-text-field outlined label="Subtotal" v-model="calculateSubtotal" readonly type="number" dense
                        class="ma-2">
                      </v-text-field>
                      <v-text-field outlined label="IVA" v-model="calculatTaxSale" readonly type="number" dense
                        class="ma-2">
                      </v-text-field>
                      <v-text-field outlined v-model="calculateTotalSale" label="Total" readonly dense class="ma-2">
                      </v-text-field>
                    </div>
                  </div>
                  <!-- Stock -->
                  <div class="d-flex align-center">
                    <h3 class="black_h3 ma-2 ml-2">En Stock:</h3>
                    <h2 class="ml-1">{{ selectProduStock }}</h2>
                    <v-btn color="black" v-if="activeLoadingInventario" icon :loading="activeLoadingInventario"></v-btn>
                  </div>
                  <!-- Grabar -->
                  <div class="d-flex mx-2 mb-4">
                    <v-spacer></v-spacer>
                    <v-btn @click="btnCreateVentadepaso" color="primary" class="rounded-lg text-capitalize"
                      :loading="activeLoadingCreateVentadepaso">Grabar</v-btn>
                  </div>
                  <!-- Productos o servicios -->
                  <div>
                    <!-- Selección insumos -->
                    <div>
                      <v-card class="mb-5 rounded-xl ma-2" elevation="0">
                        <v-card-title class="mt-4 ml-10">
                          <h2>Productos o servicios</h2>
                        </v-card-title>
                        <div class="pa-3">
                          <div class="d-flex">


                            <v-text-field v-model="searchProdus" append-icon="mdi-magnify"
                              placeholder="Escriba para buscar servicio o producto" single-line class="mb-2"
                              hide-details></v-text-field>
                            <v-btn color="primary" class="mt-1 ml-2" @click="btnSearchProductByServiece"
                              @keyup.enter="btnSearchProductByServiece">Buscar</v-btn>




                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark v-bind="attrs" v-on="on" icon class="mx-4 mt-1" @click="
                                  activeReaderCodeVentaCargarInsumos = true
                                  ">
                                  <v-icon class="mx-2 mb-1" size="50">mdi-camera</v-icon>
                                </v-btn>
                              </template>
                              <span>Usar la camara</span>
                            </v-tooltip>
                          </div>
                          <div v-if="activeReaderCodeVentaCargarInsumos">
                            <ReaderAllCode origen="ventacargarinsumos" @actualizarCodigoCargarInsumo="actualizarCodigoCargarInsumo
                              " />
                          </div>
                          <v-data-table locale="ES-ES" :loading="activeLoadingCargarProdu" :headers="headersProdus"
                            :items="produs" :search="searchProdus" hide-default-footer dense>
                            <template v-slot:item.action="{ item }">
                              <div class="actions" style="width: '10%'">
                                <v-btn class="ma-1" @click="btnSelectSaleProdu(item)" color="primary" icon>
                                  <v-icon>mdi-check-circle-outline</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <template v-slot:no-data>
                              <v-alert :value="true">
                                No hay resultados disponibles
                              </v-alert>
                            </template>
                          </v-data-table>
                        </div>
                      </v-card>
                    </div>
                    <!-- Notificación creación de control hospitalizado -->
                    <v-snackbar color="primary" v-model="successCreateVentadepaso" class="text-center">
                      Insumo cargado con éxito
                    </v-snackbar>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item key="pack">
                <v-card elevation="0">
                  <h2 class="ml-2 ma-2">Venta por pack</h2>
                  <!-- pack selecionado -->
                  <div v-if="selectPackSale">
                    <!-- titulo y accion -->
                    <v-card ref="titleprodurefpack" color="primary" class="pa-3 ma-2 white--text d-flex align-center">
                      <h3 class="white--text">{{ selectPackSale }}</h3>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" @click="selectPackSale = ''" dark icon v-bind="attrs" v-on="on">
                            <v-icon color="white"> mdi-close </v-icon>
                          </v-btn>
                        </template>
                        <span>Limpiar producto</span>
                      </v-tooltip>
                    </v-card>
                    <!-- lista de items del pack -->
                    <v-card class="pa-3 mb-5 rounded-xl ma-2" elevation="0" :loading="activeLoadingSearchPack">
                      <v-card-title class="mt-4 ml-10">
                        <h3>Lista del pack</h3>
                      </v-card-title>
                      <div class="pa-3">
                        <v-data-table locale="ES-ES" :headers="headersPack" :items="pack" hide-default-footer dense>
                          <template v-slot:no-data>
                            <v-alert :value="true">
                              No hay resultados disponibles
                            </v-alert>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card>
                  </div>
                  <!-- resumen de valor -->
                  <div>
                    <div class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                      <v-text-field outlined label="Subtotal" v-model="packSubtotalSelect" readonly type="number" dense
                        class="ma-2">
                      </v-text-field>
                      <v-text-field outlined label="IVA" v-model="packTaxSaleSelect" readonly type="number" dense
                        class="ma-2">
                      </v-text-field>
                      <v-text-field outlined v-model="packTotalSaleSelect" label="Total" readonly dense class="ma-2">
                      </v-text-field>
                    </div>
                  </div>
                  <!-- Grabar -->
                  <div>
                    <v-alert border="left" colored-border type="info" color="primary" class="mx-3 my-2" elevation="2">
                      Está acción puede demorar varios segundos, debido a a la
                      cantidad de productos o servicios del pack
                    </v-alert>
                  </div>
                  <div class="d-flex mx-2 mb-4">
                    <v-spacer></v-spacer>
                    <v-btn @click="btnCreateVentadepasoByPack" color="primary" class="rounded-lg text-capitalize"
                      :loading="activeLoadingCreateVentadepasoByPack">Cargar 100% del servicio</v-btn>
                  </div>
                  <!-- Pack -->
                  <div>
                    <!-- Seleccion pack -->
                    <div>
                      <v-card class="pa-3 mb-5 rounded-xl ma-2" elevation="0">
                        <v-card-title class="mt-4 ml-10">
                          <h2>Packs</h2>
                        </v-card-title>
                        <div class="pa-3">
                          <v-text-field v-model="searchPackcodigos" append-icon="mdi-magnify"
                            placeholder="Escriba nombre del pack" single-line class="mb-2" hide-details></v-text-field>
                          <v-data-table locale="ES-ES" :headers="headersPackcodigos" :items="packcodigos" :search="sear"
                            hide-default-footer dense>
                            <template v-slot:item.action="{ item }">
                              <div class="actions">
                                <v-btn class="ma-1" @click="btnSelectSalePack(item)" color="primary" icon>
                                  <v-icon>mdi-check-circle-outline</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <template v-slot:no-data>
                              <v-alert :value="true">
                                No hay resultados disponibles
                              </v-alert>
                            </template>
                          </v-data-table>
                        </div>
                      </v-card>
                    </div>
                    <!-- Notificación creación de control hospitalizado -->
                    <v-snackbar color="primary" v-model="successCreateVentadepaso" class="text-center">
                      Insumo cargado con éxito
                    </v-snackbar>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
          <!-- resumen de venta -->
        </div>
      </v-col>
      <!-- División escritorio -->
      <v-divider vertical class="mx-4 mb-10 d-flex d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"></v-divider>
      <!-- División móvil -->
      <v-divider class="mb-5 d-flex d-sm-flex d-md-none d-lg-none d-xl-none"></v-divider>

      <!-- Cuentas -->
      <v-col class="pa-0" cols="5">
        <div class="d-flex mb-3">
          <h2 class="ma-2 ml-4">Cuentas</h2>
        </div>
        <div class="d-flex flex-column align-center">
          <v-col class="mb-3 d-flex justify-center">
            <h3 class="black_h3 ma-2 mt-3">Tipo de cuenta</h3>
            <v-chip-group mandatory v-model="selectTipoCuenta" active-class="primary">
              <v-chip key="hospital" value="hospital"> Hospital </v-chip>
              <v-chip key="cliente" value="cliente"> Al cliente </v-chip>
            </v-chip-group>
          </v-col>
          <v-col>
            <v-row v-if="selectTipoCuenta == 'cliente'" class="d-flex">
              <v-col class="pa-2">
                <h3 class="black_h3 ma-2">Cuenta de cliente</h3>
                <v-autocomplete v-model="selectFichaCuenta" :items="setCuentaAlCliente" item-text="Ficha" outlined
                  @change="btnSelectCuentaAtencion(val)" dense label="Seleccione cuenta del cliente"
                  class="ma-2 mt-4"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="selectTipoCuenta == 'hospital'" class="d-flex">
              <v-col class="pa-2">
                <h3 class="black_h3 ma-2">Cuenta de hospital</h3>
                <v-autocomplete v-model="selectFichaCuenta" :items="setCuentaHospital" item-text="Ficha" outlined
                  :return-value.sync="val" dense label="Seleccione cuenta de hospital"
                  @change="btnSelectCuentaHospital(val)" class="ma-2 mt-4"></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </div>
        <v-divider class="mb-3"></v-divider>
        <!-- Detalle de cuenta -->
        <div class="sticky-card-detail">
          <v-col class="d-flex align-center">
            <h2 class="my-4">Detalle de cuenta</h2>
            <v-spacer></v-spacer>
            <v-btn icon @click="activeShowPrintEstadoCuentaInsumos = true"><v-icon>mdi-printer-outline</v-icon></v-btn>
          </v-col>
          <div v-if="activeShowPrintEstadoCuentaInsumos">
            <ImprimirEstadoCuentaInsumos :cuenta="selectCuenta" />
          </div>
          <!-- tabla -->
          <div class="pa-3">
            <!-- tabla con datos -->
            <v-data-table :headers="headersSales" :items.sync="selectCuenta" key="Id">
              <template v-slot:item.action="{ item }">
                <v-btn dense v-bind="attrs" v-on="on" icon @click="btnDialogDeleteSaleById(item)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.Total="{ item }">
                $ {{ item.Total | formatCurrency }}
              </template>
              <template v-slot:item.Precio="{ item }">
                $ {{ item.Precio | formatCurrency }}
              </template>
              <template v-slot:no-data>
                <v-alert :value="true"> No hay resultados disponibles </v-alert>
              </template>
            </v-data-table>

            <v-dialog v-model="dialogoDeleteSale" persistent max-width="290">
              <v-card class="pa-3">
                <v-card-title class="text-h5">
                  Seguro que quiere <br />
                  eliminar ?
                </v-card-title>
                <v-card-text>Está acción no se puede anular, y si afecta el invetario
                  volverá a cargar la disponibilidaad</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" outlined @click="btnCancelDeleteSale">
                    Cancelar
                  </v-btn>
                  <v-btn color="red" :loading="activeLoadingDeleteSale" outlined @click="btnDeleteSaleById">
                    Eliminar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div class="pa-3 d-flex justify-end">
              <strong>Total estado de cuenta $
                {{ setCalculateTotalSaleCuenta }}</strong>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ReaderAllCode from '../../../components/ReaderAllCode.vue';
import ImprimirEstadoCuentaInsumos from "../Components/ImprimirEstadoCuentaInsumos.vue";

export default {
  components: {
    ImprimirEstadoCuentaInsumos,
    ReaderAllCode,
  },
  data() {
    return {
      selectFilterProdus: "",
      searchProdus: "",
      filterProdus: [],
      searchPackcodigos: "",
      headersProdus: [
        { text: "", value: "action" },
        { text: "Producto o servicio", value: "Descripcion" },
        { text: "Codigo", value: "Codigo" },
        { text: "Precio", value: "Vtadeta" },
      ],
      headersSales: [
        { text: "Eliminar", value: "action" },
        {
          text: "Producto o servicio",
          value: "Descripcion",
        },
        { text: "Cantidad", value: "Cantidad" },
        { text: "Precio", value: "Precio" },
        { text: "Total", value: "Total" },
      ],
      headersPackcodigos: [
        {
          text: "Pack",
          value: "Descripcion",
        },
        {
          text: "Codigo",
          value: "Codigo",
        },

        { text: "", value: "action" },
      ],
      headersPack: [
        {
          text: "Item",
          value: "Descripcion",
        },
        {
          text: "Cantidad",
          value: "Cantidad",
        },
        {
          text: "Valor",
          value: "Total",
        },
      ],
      selectDoctorSupervisor: "",
      activeDev: true,
      selectProduNameSale: "",
      selectProduSale: "",
      quantitySale: "1",
      selectProduCodigoSale: "",
      selectProduPriceSale: "",
      selectProduFamiliaSale: "",
      subtotalSale: "",
      percentDiscountSale: "0",
      totalSale: "",
      selectCuenta: [],
      selectTipoCuenta: "hospital",
      selectProduStock: "0",
      activeLoadingInventario: false,
      selectDoctor: {},
      activeLoadingCreateVentadepaso: false,
      //local
      selectCuentaHospital: "",
      selectFichaCuenta: "",
      activeShowPrintEstadoCuentaInsumos: false,
      tab: null,
      selectPackSale: "",
      pack: [],
      packSubtotalSelect: 0,
      packTaxSaleSelect: 0,
      packTotalSaleSelect: 0,
      activeLoadingSearchPack: false,
      activeLoadingCreateVentadepasoByPack: false,
      selectPackCodigo: "",
      dialogoDeleteSale: false,
      selectDeleteSale: "",
      activeLoadingDeleteSale: false,
      activeReaderCodeVentaCargarInsumos: false,
    };
  },
  watch: {
    selectFilterProdus: function (val) {
      this.filterProdus = this.produs.filter((item) => item.Ubicacion == val);
    },
    successCreateVentadepaso: function (val) {
      if (val) {
        this.activeLoadingCreateVentadepaso = false;
        this.selectProduStock = "0";
        this.selectProduPriceSale = "";
        this.selectProduCodigoSale = "";
        this.selectProduNameSale = "";
        this.selectProduFamiliaSale = "";
        this.selectCuenta = this.ventasdepaso.filter(
          (item) =>
            (item.Ficha == this.selectFichaCuentaHospital) &
            (item.Origen == "HTS")
        );
        this.activeLoadingCreateVentadepasoByPack = false;
      }
    },
    successDeleteVentadepaso: function (val) {
      if (val) {
        this.selectDeleteSale = "";
        this.dialogoDeleteSale = false;
        this.activeLoadingDeleteSale = false;
        this.selectCuenta = this.ventasdepaso.filter(
          (item) =>
            (item.Ficha == this.selectFichaCuentaHospital) &
            (item.Origen == "HTS")
        );
      }
    },
  },
  computed: {
    ...mapState("auth-module", ["user", "licen"]),
    ...mapState("medical-module", [
      "produs",
      "doctors",
      "doctor",
      "ventasdepaso",
      "selectFichaCuentaHospital",
      "successCreateVentadepaso",
      "packcodigos",
      "successDeleteVentadepaso",
      "loadingVentadepaso",
      "activeLoadingCargarProdu"
    ]),
    ...mapState("customers-module", [
      "customers",
      "selectFichaClienteId",
      "cliente",
      "clientes",
      "loadingClientes",
    ]),
    setFilterProdus() {
      let filter = [];
      this.produs.forEach((element) => {
        let search = filter.findIndex(
          (item) => item.Ubicacion == element.Ubicacion
        );
        if (search == -1) {
          filter.push(element.Ubicacion);
        }
      });
      return filter;
    },
    calculateSubtotal() {
      if (this.selectProduCodigoSale) {
        let total =
          parseInt(this.quantitySale) * parseInt(this.selectProduPriceSale);
        if (this.percentDiscountSale != "0") {
          total =
            parseInt(this.quantitySale) * parseInt(this.selectProduPriceSale) -
            parseInt(this.quantitySale) *
            parseInt(this.selectProduPriceSale) *
            (parseInt(this.percentDiscountSale) / 100);
        }
        let subtotal = Math.round(total / 1.19);
        return subtotal.toString();
      }
    },
    calculatTaxSale() {
      if (this.selectProduCodigoSale) {
        let total =
          parseInt(this.quantitySale) * parseInt(this.selectProduPriceSale);
        if (this.percentDiscountSale != "0") {
          total =
            parseInt(this.quantitySale) * parseInt(this.selectProduPriceSale) -
            parseInt(this.quantitySale) *
            parseInt(this.selectProduPriceSale) *
            (parseInt(this.percentDiscountSale) / 100);
        }
        let tax = total - Math.round(total / 1.19);
        return tax.toString();
      }
    },
    calculateTotalSale() {
      if (this.selectProduCodigoSale) {
        let total =
          parseInt(this.quantitySale) * parseInt(this.selectProduPriceSale);
        if (this.percentDiscountSale != "0") {
          total =
            parseInt(this.quantitySale) * parseInt(this.selectProduPriceSale) -
            parseInt(this.quantitySale) *
            parseInt(this.selectProduPriceSale) *
            (parseInt(this.percentDiscountSale) / 100);
        }
        return total.toString();
      }
    },
    setCuentaHospital() {
      let cuentasHospital = [];
      this.ventasdepaso.forEach((item) => {
        if (item.Origen == "HTS") {
          let index = cuentasHospital.find(
            (cuenta) => (cuenta.Id = item.Ficha)
          );
          if (index != -1) {
            cuentasHospital.push(item);
          }
        }
      });
      return cuentasHospital;
    },
    setCuentaAlCliente() {
      let cuentasCliente = [];
      this.ventasdepaso.forEach((item) => {
        if (item.Origen == "Ventas") {
          let index = cuentasCliente.find((cuenta) => (cuenta.Id = item.Ficha));
          if (index != -1) {
            cuentasCliente.push(item);
          }
        }
      });
      return cuentasCliente;
    },
    setCalculateTotalSaleCuenta() {
      let total = 0;
      this.selectCuenta.forEach((item) => {
        // console.log(item);
        total += item.Total;
      });
      return total;
    },
  },
  methods: {
    ...mapActions("medical-module", [
      "getInventarioByCodigo",
      "postVentadepaso",
      "actionSelectFichaCuentaHospital",
      "getPackByCodigo",
      "postVentadepasoByPack",
      "postDeleteVentadepasoById",
      "getProdusByNameOrCode"
    ]),
    ...mapActions("customers-module", ["getClienteByFichan"]),
    setProdus() {
      this.produs.forEach((element) => {
        element.Despcod = element.Descripcion + " / " + element.Codigo;
      });
    },
    async btnSelectSaleProdu(produ) {
      this.selectProduStock = "0";
      this.selectProduPriceSale = produ.Vtadeta;
      this.selectProduCodigoSale = produ.Codigo;
      this.selectProduNameSale = produ.Descripcion;
      this.selectProduFamiliaSale = produ.Ubicacion;

      var element = this.$refs["titleprodurefind"];
      var top = element.offsetTop;
      window.scrollTo(0, top - 101);

      if (produ.Afectainv == "SI") {
        this.activeLoadingInventario = true;
        let inventario = await this.getInventarioByCodigo({
          codigo: produ.Codigo,
          sucursal: this.user.Sucursal,
        });
        if (inventario.Cod) {
          this.selectProduStock = inventario.Saldos;
        }
        this.activeLoadingInventario = false;
      }
    },
    async btnSelectSalePack(pack) {
      // this.selectPackPriceSale = "";
      // this.selectPackCodigoSale = "produ.Codigo";
      this.selectPackSale = pack.Descripcion;

      var element = this.$refs["titleprodurefpack"];
      var top = element.offsetTop;
      window.scrollTo(0, top - 100);

      this.activeLoadingSearchPack = true;
      this.pack = await this.getPackByCodigo({ packcodigo: pack.Codigo });
      this.selectPackCodigo = pack.Codigo;
      this.packSubtotalSelect = 0;
      this.packTaxSaleSelect = 0;
      this.packTotalSaleSelect = 0;
      let total = 0;

      this.pack.forEach((item) => {
        total += parseInt(item.Cantidad) * item.Total;
      });
      this.packSubtotalSelect = Math.round(total / 1.19);
      this.packTaxSaleSelect = Math.round(total - total / 1.19);
      this.packTotalSaleSelect = total;
      this.activeLoadingSearchPack = false;
      // if (produ.Afectainv == "SI") {
      //   this.activeLoadingInventario = true;
      //   let inventario = await this.getInventarioByCodigo({
      //     codigo: produ.Codigo,
      //   });
      //   if (inventario.Cod) {
      //     this.selectProduStock = inventario.Saldos;
      //   }
      //   this.activeLoadingInventario = false;
      // }
    },
    btnCleanSelectSalePro() {
      this.selectProduPriceSale = "";
      this.selectProduCodigoSale = "";
      this.selectProduNameSale = "";
      this.selectProduStock = "0";
    },
    async btnSelectCuentaHospital() {
      this.actionSelectFichaCuentaHospital(this.selectFichaCuenta);
      if (!this.cliente.Ficha) {
        await this.getClienteByFichan(this.selectFichaCuenta);
      }
      this.selectCuenta = this.ventasdepaso.filter(
        (item) =>
          (item.Ficha == this.selectFichaCuenta) & (item.Origen == "HTS")
      );
    },
    async btnSelectCuentaAtencion() {
      this.actionSelectFichaCuentaHospital(this.selectFichaCuenta);
      if (!this.cliente.Ficha) {
        await this.getClienteByFichan(this.selectFichaCuenta);
      }
      this.selectCuenta = this.ventasdepaso.filter(
        (item) =>
          (item.Ficha == this.selectFichaCuenta) & (item.Origen == "Ventas")
      );
    },
    loadingStartCuentaHospital() {
      if (this.selectFichaCuentaHospital) {
        this.selectFichaCuenta = this.selectFichaCuentaHospital;
        this.btnSelectCuentaHospital();
      }
    },
    btnSearchProductByServiece() {
      if (!this.searchProdus) {
        alert("Debe ingresar un parametro de busqueda")
        return
      }
      this.getProdusByNameOrCode({ search: this.searchProdus })
    },
    btnCreateVentadepaso() {
      let ventadepaso = {
        Ficha: 0,
        Mascota: "",
        Propietario: "",
        Codigo: "",
        Descripcion: "",
        Cantidad: 0,
        Precio: 0,
        Descuento: 0,
        Subtotal: 0,
        Impuesto: 0,
        Total: 0,
        Quienvende: "",
        Fecha: "",
        Familia: "",
        Fecha2: "",
        Var1: ".",
        Var2: ".",
        Var3: "",
        Origen: "HTS",
        Turno: "",
        Hora: "",
        Sucursal: "",
        Exento: 0,
        Tipodte: 0,
        Frmt: "n",
      };
      if (!this.cliente.Fichan) {
        alert("No ha seleccionado un cliente");
        return;
      }
      if (!this.selectProduCodigoSale) {
        alert("No ha seleccionado un producto o servicio");
        return;
      }
      ventadepaso.Ficha = this.cliente.Fichan;
      ventadepaso.Mascota = this.cliente.Nombre;
      ventadepaso.Propietario = this.cliente.Amo;
      ventadepaso.Codigo = this.selectProduCodigoSale;
      ventadepaso.Descripcion = this.selectProduNameSale;
      ventadepaso.Cantidad = parseInt(this.quantitySale);
      ventadepaso.Precio = this.selectProduPriceSale;
      ventadepaso.Descuento = parseInt(this.percentDiscountSale);
      ventadepaso.Subtotal = 0;
      ventadepaso.Impuesto = 0;
      ventadepaso.Total = 0;
      ventadepaso.Fecha = new Date().toISOString().substring(0, 10);
      ventadepaso.Familia = this.selectProduFamiliaSale;
      if (this.doctor.Nombre) {
        ventadepaso.Quienvende = this.doctor.Nombre;
        ventadepaso.Var3 = this.doctor.Rut;
      } else {
        ventadepaso.Quienvende = this.selectDoctorSupervisor.Nombre;
        ventadepaso.Var3 = this.selectDoctorSupervisor.Rut;
      }
      ventadepaso.Origen = "HTS";
      ventadepaso.Hora =
        new Date().toISOString().substring(0, 10) +
        " " +
        new Date().toISOString().substring(11, 16);
      ventadepaso.Sucursal = this.user.Sucursal;
      this.activeLoadingCreateVentadepaso = true;
      this.postVentadepaso({ ventadepaso: ventadepaso });
    },
    btnCreateVentadepasoByPack() {
      let ventadepaso = {
        Ficha: 0,
        Mascota: "",
        Propietario: "",
        Codigopack: "",
        Descripcion: "",

        Quienvende: "",
        Fecha: "",

        Fecha2: "",
        Var1: ".",
        Var2: ".",
        Var3: "",
        Origen: "HTS",
        Turno: "",
        Hora: "",
        Sucursal: "",
      };
      if (!this.cliente.Fichan) {
        alert("No ha seleccionado un cliente");
        return;
      }
      if (!this.selectPackSale) {
        alert("No ha seleccionado un pack");
        return;
      }
      ventadepaso.Ficha = this.cliente.Fichan;
      ventadepaso.Mascota = this.cliente.Nombre;
      ventadepaso.Propietario = this.cliente.Amo;
      ventadepaso.Codigopack = this.selectPackCodigo;
      ventadepaso.Descripcion = this.selectPackSale;
      ventadepaso.Fecha = new Date().toISOString().substring(0, 10);
      if (this.doctor.Nombre) {
        ventadepaso.Quienvende = this.doctor.Nombre;
        ventadepaso.Var3 = this.doctor.Rut;
      } else {
        ventadepaso.Quienvende = this.selectDoctorSupervisor.Nombre;
        ventadepaso.Var3 = this.selectDoctorSupervisor.Rut;
      }
      ventadepaso.Origen = "HTS";
      ventadepaso.Hora =
        new Date().toISOString().substring(0, 10) +
        " " +
        new Date().toISOString().substring(11, 16);
      ventadepaso.Sucursal = this.user.Sucursal;
      this.activeLoadingCreateVentadepasoByPack = true;
      this.postVentadepasoByPack({ ventapack: ventadepaso });
    },
    async btnDeleteSaleById() {
      this.activeLoadingDeleteSale = true;
      this.postDeleteVentadepasoById({ id: this.selectDeleteSale });
    },
    async btnDialogDeleteSaleById(sale) {
      this.selectDeleteSale = sale.Id;
      this.dialogoDeleteSale = true;
    },
    async btnCancelDeleteSale() {
      this.selectDeleteSale = "";
      this.dialogoDeleteSale = false;
    },
    actualizarCodigoCargarInsumo(code) {
      this.searchProdus = code
      let searchValid = this.produs.find((item) => item.Codigo == code);
      if (searchValid.Id) {
        this.activeReaderCodeVentaCargarInsumos = false;
      }
      if (!searchValid.Id) {
        alert("Codigo no encontrado");
        return;
      }
    },
  },
  created() {
    this.loadingStartCuentaHospital();
  },
};
</script>
<style>
/* Título de la página */
.sticky-card {
  position: sticky;
  top: 100px;
  z-index: 1;
  background: #fff;
}

.sticky-card-detail {
  position: sticky;
  top: 220px;
  z-index: 1;
  background: #fff;
}

.titleContainer {
  z-index: 2;
}

/* Eliminar select Sort By desplegado en vista móvil de tabla  */
.v-data-table-header-mobile__wrapper {
  display: none;
  border-style: none;
}

.v-data-table-header-mobile {
  display: none;
}
</style>
